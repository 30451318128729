/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import React, { useContext, useEffect, useState } from 'react';

import './video-scroll-component.scss';

import videoFlagLoop from '../../assets/flag.mp4';
import videoKeysLoop from '../../assets/keys.mp4';
import videoTicketsLoop from '../../assets/ticket.mp4';
import videoPosterLoop from '../../assets/poster.mp4';
import videoCarouselLoop from '../../assets/carousel.mp4';

type VideoScrollComponentType = {
} & typeof defaultProps;
const defaultProps = {
};

let prevPosY = 0;

const FRAME_COUNT = 150;

const VideoScrollComponent = (props: VideoScrollComponentType) => {
  const [currentPosY, setCurrentPosY]: any = useState(1);
  const [canScroll, setCanScroll]: any = useState(true);
  const [toggleShowVideo, setToggleShowVideo]: any = useState(true);
  const [browserHeight, setBrowserHeight]: any = useState(0);

  const [images, setImages]: any = useState(['']);

  const handleScroll = (e: any) => {
    const posY = window.scrollY;

    setCurrentPosY(posY);
    prevPosY = posY;

    console.log();

    if (posY <= 1) window.scrollTo(0, 1);

    if (posY >= 70) {
      setToggleShowVideo(false);
    } else {
      setToggleShowVideo(true);
    }
  };

  const adjustHeight = () => {
    const height = window.innerHeight;
    setBrowserHeight(height);
  };

  useEffect(() => {
    const height = window.innerHeight;
    setBrowserHeight(height);

    // preloadImages();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', adjustHeight);
  }, []);

  return (
    <>
      <div className={`video-scroll-component ${toggleShowVideo ? 'show-helment' : ''}`}>
        <div style={{ backgroundImage: `url(${images[currentPosY]})` }} id="helmet" />
      </div>
      <div className={`main-content ${toggleShowVideo ? '' : 'show-content'}`}>
        <div className="content-section garage" style={{ height: browserHeight + 350 }}>
          <></>
        </div>
        <div className="content-section club" style={{ height: browserHeight }}>
          <br />
          <br />
          {/* <h2>About Automobilist Driver&apos;s Club</h2> */}
          <div className="copy-left">
            <b>JOIN THE CLUB</b>
            <br />
            <br />
            5,000 unique racing helmets. Build your own helmet and join a one-of-a-kind
            community of automotive aficionados, art enthusiasts and rev-head revolutionaries.
          </div>
          <video className="square-image img-1" autoPlay loop muted playsInline>
            <source src={videoFlagLoop} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video className="square-image img-2" autoPlay loop muted playsInline>
            <source src={videoKeysLoop} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video className="square-image img-3" autoPlay loop muted playsInline>
            <source src={videoTicketsLoop} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="content-section carousel" style={{ height: browserHeight }}>
          <h2>5000 CLUB MEMBERS</h2>
          <video autoPlay loop muted playsInline>
            <source src={videoCarouselLoop} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="content-section roadmap" style={{ height: browserHeight + 300 }}>
          <h2>ROADMAP</h2>
          <div className="roadmap-container" />
        </div>
        <div className="content-section utility">
          <h2>TEAM UTILITY</h2>
          <div className="utility-section">
            <div className="utility-container everyone">
              <span>Rewards for Everyone</span>
              <div className="rewards-container">
                <video autoPlay loop muted playsInline>
                  <source src={videoFlagLoop} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <video autoPlay loop muted playsInline>
                  <source src={videoPosterLoop} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="utility-container winners">
              <span>Rewards for Winners</span>
              <div className="rewards-container">
                <video autoPlay loop muted playsInline>
                  <source src={videoTicketsLoop} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <video autoPlay loop muted playsInline>
                  <source src={videoKeysLoop} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section partners">
          <h2>PARTNERS</h2>
          <div className="partner-container">
            <div className="partner partner-a" />
            <div className="partner partner-b" />
            <div className="partner partner-c" />
          </div>
        </div>
        <div className="content-section team">
          <h2>TEAM</h2>
          <div className="team-container">
            <div className="member">
              <div className="logo member-a" />
              <div className="about">
                Automobilist are a team of artists, creative thinkers,
                innovators and automotive aficionados. We push the boundaries
                of automotive art and culture by utilising technology,
                uncovering history and striving for perfection.
              </div>
            </div>
            <div className="member">
              <div className="logo member-b" />
              <div className="about">
                Run It Wild are a full service NFT agency specialising
                in end-to-end NFT creative and implementation.
              </div>
            </div>
            <div className="member">
              <div className="logo member-c" />
              <div className="about">
                Pellar Technology are a team of blockhain native
                developers implementing web3 and smart contract projects.
              </div>
            </div>
          </div>
        </div>
        <div className="footer" />
      </div>
    </>
  );
};

VideoScrollComponent.defaultProps = defaultProps;

export default VideoScrollComponent;
