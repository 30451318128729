/* eslint-disable */

import React, {
  useContext, useEffect, useLayoutEffect, useState, useRef, Suspense, useMemo,
} from 'react';

import {
  ToastContainer,
  toast,
} from 'react-toastify';

import EmailPopupComponent from '../EmailPopupComponent/EmailPopupComponent';

import './main-site.scss';

import texture from '../../assets/Automobilist_0003_tex.png';
import videoCarouselLoop from '../../assets/carousel.mp4';
import videoCarBuildingLoop from '../../assets/car-building.mp4';
import videoDrivingLoop from '../../assets/driving.mp4';
import HelmetModel from './HelmetModel';
import MapItemPopup from '../MapItemPopup';

import mobileIntroHeader from '../../assets/mobile-intro-header.mp4';

import newHelmet from '../../assets/utility/new/helmet.mp4';
import newCoffee from '../../assets/utility/new/coffee.mp4';
import newBadge from '../../assets/utility/new/badge.mp4';
import newController from '../../assets/utility/new/trophy.mp4';
import newFlag from '../../assets/utility/new/flag.mp4';
import newPoster from '../../assets/utility/new/poster.mp4';
import newKey from '../../assets/utility/new/keys.mp4';
import newSteering from '../../assets/utility/new/steering.mp4';
import newTickets from '../../assets/utility/new/tickets.mp4';

let canStartCycle = true;
let introVideoTimer: any = null;

type MainSiteType = {
} & typeof defaultProps;
const defaultProps = {
};

const FRAME_COUNT = 150;

const MainSite = (props: MainSiteType) => {
  const vidRef = useRef(null);
  const [tx, setTx] = useState(texture);

  const [emailPopupActive, setEmailPopupActive]: any = useState(false);
  const [mobileMenuActive, setMobileMenuActive]: any = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [canInteract, setCanInteract] = useState(false);
  const [currentPosY, setCurrentPosY]: any = useState(1);
  const [helmetFrame, setHelmetFrame]: any = useState(1);

  const [loadHelmet, setLoadHelmet]: any = useState(false);
  const [didMount, setDidMount]: any = useState(false);
  
  const [canScroll, setCanScroll]: any = useState(true);
  const [toggleShowVideo, setToggleShowVideo]: any = useState(true);
  const [browserHeight, setBrowserHeight]: any = useState(0);
  const [mainStep, setMainStep]: any = useState(0);
  const [activeRoadmapPopup, setActiveRoadmapPopup]: any = useState(3);

  const [helmetStepColour, setHelmetStepColour]: any = useState('#182858');

  const [images, setImages]: any = useState(['']);


  useEffect(() => {
      if (isMobile) {

        if (currentPosY <= 0) {
          window.scrollTo(0,0);
          canStartCycle = true;
          onClickIntro();
        }

        const mob = document.getElementById("mobileIntroVideo");
        if (currentPosY > 1000 && canStartCycle) {
          canStartCycle = false;
          onClickIntro();
        // @ts-ignore
        } else if (currentPosY > 500 && currentPosY < 1000 && !canStartCycle && mob.currentTime === 0) {
          window.scrollTo(0,0);
          canStartCycle = true;
          onClickIntro();
        } else if (currentPosY > 100 && currentPosY < 500 && canStartCycle) {
          // @ts-ignore
          if (mob.currentTime === 0) onClickCycle();
        }
      }
  }, [currentPosY]);

  const onClickIntro = () => {
    const mob = document.getElementById("mobileIntroVideo");
    if (!mob) return;
    if (introVideoTimer) clearInterval(introVideoTimer);
    // @ts-ignore
    mob.currentTime = 0;
    // @ts-ignore
    mob.pause();
  };

  const onClickCycle = () => {
    canStartCycle = true;
    if (introVideoTimer) clearInterval(introVideoTimer);
    const mob = document.getElementById("mobileIntroVideo");
    if (!mob) return;
    // @ts-ignore
    mob.currentTime = 0;
    // @ts-ignore
    mob.play();
    // @ts-ignore
    introVideoTimer = setInterval(() => {
      // @ts-ignore
      const ct = mob.currentTime;
      if (ct >= 15) {
        clearInterval(introVideoTimer);
        // @ts-ignore
        mob.pause();
        window.scrollTo(0, 1050)
        setTimeout(() => {
          // @ts-ignore
          mob.currentTime = 0;
          canStartCycle = false;
        }, 100);
      }
    }, 100);
  };

  const handleScroll = (e: any) => {
    const posY = parseInt(window.scrollY.toString());
    setMainStep(0);
  
    if (isMobile) {
      if (posY <= 1) window.scrollTo(0, 0);
    } else {
      if (posY > 9000) setLoadHelmet(true);
      if (posY <= 1) window.scrollTo(0, 1);
      if (posY >= 3900 && posY < 4600) {
        setToggleShowVideo(false);
        setMainStep(1);
      } else if (posY > 4600) {
        setMainStep(2);
      } else if (posY < 3900) {
        setToggleShowVideo(true);
        setMainStep(0);
      } else {
        setToggleShowVideo(false);
      }
    }

    setCurrentPosY(posY);

  };

  const adjustHeight = () => {
    const height = window.innerHeight;
    setBrowserHeight(height);
  };

  const mount = () => {
    setToggleShowVideo(false);
    setTimeout(() => {
      setToggleShowVideo(true);
      window.scrollTo(0, 0);
    }, 300);
    setTimeout(() => {
      window.scrollTo(0, 0);
      setToggleShowVideo(true);
      onClickIntro();
      setTimeout(() => {
        setCanInteract(true);
      }, 2000);
    }, 2000);
  };

  useEffect(() => {
    mount();
  }, []);

  useEffect(() => {
    const height = window.innerHeight;
    setBrowserHeight(height);

    window.addEventListener('scroll', (e) => handleScroll(e));
    window.addEventListener('resize', () => adjustHeight());
  }, []);

  useEffect(() => {
    if (isMobile) {

    } else {
      if (currentPosY < 1300) {
        setHelmetStepColour('#4293BF');
      } else if (currentPosY > 1300 && currentPosY < 2600) {
        setHelmetStepColour('#B42418');
      } else if (currentPosY > 2600 && currentPosY < 3700) {
        setHelmetStepColour('#CAAF70');
      } else if (currentPosY > 3700) {
        setHelmetStepColour('#000000');
      }
      if (currentPosY < 100) {
        setHelmetFrame(1);
      } if (currentPosY > 100) {
        setHelmetFrame(2);
      } if (currentPosY > 200) {
        setHelmetFrame(3);
      } if (currentPosY > 300) {
        setHelmetFrame(4);
      } if (currentPosY > 400) {
        setHelmetFrame(5);
      } if (currentPosY > 500) {
        setHelmetFrame(6);
      } if (currentPosY > 600) {
        setHelmetFrame(7);
      } if (currentPosY > 700) {
        setHelmetFrame(8);
      } if (currentPosY > 800) {
        setHelmetFrame(9);
      } if (currentPosY > 900) {
        setHelmetFrame(10);
      } if (currentPosY > 1000) {
        setHelmetFrame(11);
      } if (currentPosY > 1100) {
        setHelmetFrame(12);
      } if (currentPosY > 1200) {
        setHelmetFrame(13);
      } if (currentPosY > 1300) {
        setHelmetFrame(14);
      } if (currentPosY > 1400) {
        setHelmetFrame(15);
      } if (currentPosY > 1500) {
        setHelmetFrame(16);
      } if (currentPosY > 1600) {
        setHelmetFrame(17);
      } if (currentPosY > 1700) {
        setHelmetFrame(18);
      } if (currentPosY > 1800) {
        setHelmetFrame(19);
      } if (currentPosY > 1900) {
        setHelmetFrame(20);
      } if (currentPosY > 2000) {
        setHelmetFrame(21);
      } if (currentPosY > 2100) {
        setHelmetFrame(22);
      } if (currentPosY > 2200) {
        setHelmetFrame(23);
      } if (currentPosY > 2300) {
        setHelmetFrame(24);
      } if (currentPosY > 2400) {
        setHelmetFrame(25);
      } if (currentPosY > 2500) {
        setHelmetFrame(26);
      } if (currentPosY > 2600) {
        setHelmetFrame(27);
      } if (currentPosY > 2700) {
        setHelmetFrame(28);
      } if (currentPosY > 2800) {
        setHelmetFrame(29);
      } if (currentPosY > 2900) {
        setHelmetFrame(30);
      } if (currentPosY > 3000) {
        setHelmetFrame(31);
      } if (currentPosY > 3100) {
        setHelmetFrame(32);
      } if (currentPosY > 3200) {
        setHelmetFrame(33);
      } if (currentPosY > 3300) {
        setHelmetFrame(34);
      } if (currentPosY > 3400) {
        setHelmetFrame(35);
      } if (currentPosY > 3500) {
        setHelmetFrame(36);
      } if (currentPosY > 3600) {
        setHelmetFrame(37);
      } if (currentPosY > 3700) {
        setHelmetFrame(38);
      } if (currentPosY > 3800) {
        setHelmetFrame(39);
      } if (currentPosY > 3900) {
        setHelmetFrame(40);
      }
    }
  }, [currentPosY]);

  const navTwitter = () => {
    window.open('https://twitter.com/MetaDriversClub', '_blank', 'noopener');
  };

  const navDiscord = () => {
    toast.info('Discord coming soon...');
    // window.open('https://discord.gg/automobilist', '_blank', 'noopener');
  };

  const onClickHome = () => {
    window.scrollTo(0, 1);
  };

  const toggleEmailPopup = () => {
    setEmailPopupActive(!emailPopupActive);
  };

  const navClick = (sectionId: string) => {
    setMobileMenuActive(false);
    if (!sectionId) return;
    document.getElementById(sectionId).scrollIntoView({
      behavior: 'smooth',
    });

    if (isMobile) {
      onClickIntro();
    }
  };

  const checkIsMobile = () => {
    if (didMount) return;
    const browserWidth = window.innerWidth;
    setIsMobile(browserWidth <= 1000);
    setTimeout(() => {
      setDidMount(true);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', () => checkIsMobile());
    setTimeout(() => {
      checkIsMobile();
    }, 1000);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuActive(!mobileMenuActive);
  };

  const closeActivePopups = (popupId: number) => {
    setActiveRoadmapPopup(popupId);
  };

  const litepaperClick = () => {
    toast.info('Litepaper coming soon...');
    // window.open('', '_blank', 'noopener');
  };

  return (

    !didMount ? (
      <></>
    ) : (
      <div style={canInteract ? {pointerEvents: "auto"} : {pointerEvents: "none"}}>

        {emailPopupActive ? (
          <EmailPopupComponent toggleEmailPopup={toggleEmailPopup} />
        ) : (
          <ToastContainer position="top-left" autoClose={10000} />
        )}

        <div className="header-nav">
          <div className="header-logo" onClick={() => onClickHome()}/>
          <ul className="menu-navigation">
            <li onClick={() => navClick('about-section')}>ABOUT</li>
            <li onClick={() => navClick('partners-section')}>PARTNERS</li>
            {/* <li onClick={() => navClick('ambassadors-section')}>AMBASSADORS</li> */}
            <li onClick={() => navClick('members-section')}>MEMBERS</li>
            <li onClick={() => navClick('nft-section')}>NFT</li>
            <li onClick={() => navClick('roadmap-section')}>ROADMAP</li>
            <li onClick={() => litepaperClick()}>LITEPAPER</li>
            <li onClick={() => navClick('team-section')}>TEAM</li>
          </ul>
          <div className="social-icon twitter" onClick={() => navTwitter()} />
          <div className="social-icon discord" onClick={() => navDiscord()} />
        </div>

        <div className={`mobile-menu-icon ${mobileMenuActive ? 'show-close-menu' : ''}`} onClick={() => toggleMobileMenu()} />

        {mobileMenuActive ? (
          <ul className="menu-navigation mobile-nav">
            <li onClick={() => navClick('about-section')}>ABOUT</li>
            <li onClick={() => navClick('partners-section')}>PARTNERS</li>
            {/* <li onClick={() => navClick('ambassadors-section')}>AMBASSADORS</li> */}
            <li onClick={() => navClick('members-section')}>MEMBERS</li>
            <li onClick={() => navClick('nft-section')}>NFT</li>
            <li onClick={() => navClick('roadmap-section')}>ROADMAP</li>
            <li onClick={() => litepaperClick()}>LITEPAPER</li>
            <li onClick={() => navClick('team-section')}>TEAM</li>
          </ul>
        ) : <></>}

        <div className={`helmet-intro ${toggleShowVideo ? 'show-helment' : ''}`} style={{ minHeight: browserHeight }}>
        
        {isMobile ? (
            <>

            </>
          ) : (
            <>
              <h2 className={`header-title ${helmetStepColour === '#4293BF' ? 'show-title' : ''}`}>
                METAVERSE
                <br />
                DRIVER&apos;S
                <br />
                CLUB
              </h2>
            </>
          )
        }
                
          {isMobile ? (
            <>
              {/* <div
                className={`mobile-next-intro-button ${currentPosY < 1000 ? 'mobile-next-intro-button-active' : ''}`}
                onClick={() => onClickCycle()}
              >
                NEXT
              </div> */}
            </>
          ) : (
            <>
              <span className={`header-sub-copy ${helmetStepColour === '#4293BF' ? 'header-sub-copy-active' : ''}`}>
                <br />
                The premier Web3
                <br />
                automotive community.
                <br />
                {/* Free-to-mint +
                <div className="gas-icon" /> */}
              </span>
              <div className={`header-cta-button ${helmetStepColour === '#4293BF' ? 'header-cta-button-active' : ''}`} onClick={() => navDiscord()}>
                JOIN DISCORD
              </div>
              <div className={`header-register ${helmetStepColour === '#4293BF' ? 'header-register-active' : ''}`} onClick={() => toggleEmailPopup()}>
                Register for notifications
              </div>

              <div className={`header-scroll-more ${helmetStepColour === '#4293BF' ? 'header-scroll-more-active' : ''}`}>
                Scroll for more
              </div>

              <h2 className={`header-title ${helmetStepColour === '#B42418' ? 'show-title' : ''}`}>
                BUILDING A HAVEN
                <br />
                FOR AUTOMOTIVE
                <br />
                ENTHUSIASTS AND
                <br />
                MOTORSPORT
                <br />
                FANATICS
              </h2>

              <h2 className={`header-title ${helmetStepColour === '#CAAF70' ? 'show-title' : ''}`}>
                HANDCRAFTED
                <br />
                BY AUTOMOBILIST
              </h2>

              <h2 className={`header-title driven-by-you ${helmetStepColour === '#CAAF70' ? 'show-title' : ''}`}>
                DRIVEN BY YOU
              </h2>

              <div className="helmet-image" />
            </>
          )
        }

          {isMobile ? (
            <>

            </>
          ) : (
            <>
              <div className={`helmet-container h1 ${helmetFrame === 1 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h2 ${helmetFrame === 2 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h3 ${helmetFrame === 3 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h4 ${helmetFrame === 4 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h5 ${helmetFrame === 5 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h6 ${helmetFrame === 6 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h7 ${helmetFrame === 7 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h8 ${helmetFrame === 8 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h9 ${helmetFrame === 9 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h10 ${helmetFrame === 10 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h11 ${helmetFrame === 11 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h12 ${helmetFrame === 12 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h13 ${helmetFrame === 13 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h14 ${helmetFrame === 14 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h15 ${helmetFrame === 15 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h16 ${helmetFrame === 16 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h17 ${helmetFrame === 17 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h18 ${helmetFrame === 18 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h19 ${helmetFrame === 19 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h20 ${helmetFrame === 20 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h21 ${helmetFrame === 21 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h22 ${helmetFrame === 22 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h23 ${helmetFrame === 23 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h24 ${helmetFrame === 24 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h25 ${helmetFrame === 25 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h26 ${helmetFrame === 26 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h27 ${helmetFrame === 27 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h28 ${helmetFrame === 28 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h29 ${helmetFrame === 29 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h30 ${helmetFrame === 30 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h31 ${helmetFrame === 31 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h32 ${helmetFrame === 32 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h33 ${helmetFrame === 33 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h34 ${helmetFrame === 34 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h35 ${helmetFrame === 35 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h36 ${helmetFrame === 36 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h37 ${helmetFrame === 37 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h38 ${helmetFrame === 38 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h39 ${helmetFrame === 39 ? 'show-helmet' : ''}`} />
              <div className={`helmet-container h40 ${helmetFrame === 40 ? 'show-helmet' : ''}`} />
            </>
          )}


          {isMobile ? (
            <>
              <div
                className={`mobile-intro-header ${currentPosY > 1000 ? 'hide-mobile-video' : ''}`}
                style={{height: browserHeight }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:`
                    <video
                      muted
                      playsinline
                      autoplay
                      id="mobileIntroVideo"
                      preload="yes"
                    >
                      <source src=${mobileIntroHeader} type="video/mp4" />
                    </video>
                  `,
                  }}
                />
              </div>

              <div className="mobile-nav-footer">
                <span>
                  The premier Web3 automotive community.
                </span>
                <div className={`mobile-header-cta-button`} onClick={() => navDiscord()}>
                  JOIN DISCORD
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="stripe-one" />
              <div className="stripe-two" />
              <div className="stripe-three" />
              <div className="stripe-four" />
              <div className="stripe-five" />
              <div className={`racing-transition ${helmetFrame >= 34 ? 'show-helmet' : ''}`}>
                <video autoPlay loop muted playsInline>
                  <source src={videoDrivingLoop} type="video/mp4" />
                </video>
              </div>
            </>
          )
        }
        </div>
        <div
          id="section-one"
          className={`main-content ${toggleShowVideo ? '' : 'show-content'} ${currentPosY > 1000 && isMobile ? 'show-content' : ''}`}
        >
          <div className="content-section garage" style={{ marginTop: isMobile ? 200 : 3800, minHeight: isMobile ? 0 : 1200, height: isMobile ? 0 : browserHeight + 350 }}>
            {isMobile ? (
              <></>
            ) : (
              <>
                <h2 className={`header-title ${mainStep === 1 ? 'show-title' : ''}`}>
                  A BRAND NEW
                  <br />
                  AUTOMOTIVE
                  <br />
                  WORLD AWAITS
                </h2>
                <video autoPlay loop muted playsInline>
                  <source src={videoDrivingLoop} type="video/mp4" />
                </video>
              </>
            )}
          </div>

          <div id="about-section" className="content-section about-container" style={{ minHeight: browserHeight, height: browserHeight }}>
            <h2 className={`header-title ${mainStep === 2 ? 'show-title' : 'show-title'}`}>
              About
              <br />
              Automobilist

              <div className="details-mission">
                Our mission is to showcase the greatest moments and machines in motoring history by connecting modern technology and art.
                In partnership with iconic automotive personalities and brands, we are perfectionists in our craft.
              </div>
            </h2>

            <div className="about-image-container">
              {currentPosY > 800 ? (
                <video autoPlay loop muted playsInline>
                  <source src={videoCarBuildingLoop} type="video/mp4" />
                </video>
              ) : <></>}
            </div>

            <div className="details-container">
              <div className="detail-item">
                <div className="title-text">
                  Engines. Art.
                  <br />
                  Technology.
                </div>
                <div className="copy-details">
                  Our system that utilises both professional photography and cutting-edge computer graphics allows us to
                  recreate historic machines and moments like never before.
                </div>
              </div>
              <div className="detail-item">
                <div className="title-text">
                  Down to each buckle
                  and bolt.
                </div>
                <div className="copy-details">
                  Attention to detail what sets this art apart. Each fine art piece takes approximately 2-3 months of craft,
                  with a team of highly talented individuals, and up to 50 cast and crew members.
                </div>
              </div>
              <div className="detail-item">
                <div className="title-text">
                  Automotive
                  <br />
                  authenticity.
                </div>
                <div className="copy-details">
                  We spend weeks on research to guarantee every detail is accurate. Through our iconic partnerships,
                  everything down to the smallest nuts and bolts are meticulously developed and unquestionably authentic.
                </div>
              </div>
            </div>
          </div>

          <div id="partners-section" className="content-section partners" style={{ minHeight: browserHeight, height: browserHeight }}>
            <h2 className={`header-title ${mainStep === 3 ? 'show-title' : 'show-title'}`}>
              Automobilist&apos;s
              <br />
              Official Partners
            </h2>
            <div className="partner-container">
              <div className="partner partner-a">
                <div className="info-name">
                  Metakey
                </div>
              </div>
              <div className="partner partner-b">
                <div className="info-name">
                  Decentraland
                </div>
              </div>
            </div>
          </div>

          {/* <div id="ambassadors-section" className="content-section ambassadors" style={{ minHeight: browserHeight }}>
            <h2 className={`header-title ${mainStep === 4 ? 'show-title' : 'show-title'}`}>
              Automobilist&apos;s
              <br />
              Official
              <br />
              Ambassadors
            </h2>

            <div className="partner-container">
              <div className="partner partner-d">
                <div className="info-name">
                  DAVID
                  <br />
                  COULTHARD
                </div>
                <div className="info-bio">
                  Quick witted, entertaining and sharp as a razor, "DC" has reinvented himself from an F1 driver,
                  with 13 Grand Prix wins under the belt, into a very successful businessman, TV pundit, and is
                  the current President of the British Racing Drivers' Club and on the
                  advisory board of the all-women W Series.
                </div>
              </div>
              <div className="partner partner-e">
                <div className="info-name">
                  KARUN
                  <br />
                  CHANDHOK
                </div>
                <div className="info-bio">
                  Karun Chandhok's career as a racing driver has taken him all the way to F1 as well as Formula E.
                  Since hanging up his helmet, Karun has really made a name for himself as an analyst and commentator,
                  is on the Board of Directors of Motorsport UK and a member of the Equality,
                  Diversion and Inclusion Committee.
                </div>
              </div>
              <div className="partner partner-f">
                <div className="info-name">
                  PAUL
                  <br />
                  DI RESTA
                </div>
                <div className="info-bio">
                  Paul di Resta's victory in F3 in 2006 paved his way to the German Touring Car series with Mercedes,
                  where he wound up champion after an enthralling 2010 season. He is a familiar face to F1 viewers
                  in the UK as a co-commentator but continues to prove his skill in 24H Le Mans races.
                </div>
              </div>
            </div>
          </div> */}

          <div id="members-section" className="content-section carousel" style={{ minHeight: browserHeight }}>
            <h2 className={`header-title ${mainStep === 5 ? 'show-title' : 'show-title'}`}>
              5000 CLUB
              <br />
              MEMBERS
              <div className="members-details">
                Rallying a community of
                <br />
                automotive enthusiasts
                <br/>
                together to shape the
                <br />
                future of motorsport
                <br />
                in Web3.
              </div>
            </h2>
            {currentPosY > 4000 ? (
              <video autoPlay loop muted playsInline>
                <source src={videoCarouselLoop} type="video/mp4" />
              </video>
            ) : <></>}
          </div>

          <div id="nft-section" className="content-section one-of-them" style={{ minHeight: browserHeight }}>
            <h2 className={`header-title ${mainStep === 6 ? 'show-title' : 'show-title'}`}>
              Will you
              <br />
              be one
              <br />
              of them?
              <div className="members-details">
                GLB based interactive 3D
                <br />
                NFTs, each painstakingly
                <br />
                crafted by Automobilist.
              </div>
            </h2>

            <div className="hero-content">
              {loadHelmet && !isMobile
                ? (
                  <div className="helmet-glb">
                    <Suspense fallback="">
                      <HelmetModel />
                    </Suspense>
                  </div>
                )
                : (
                  <video autoPlay loop muted playsInline>
                    <source src={newHelmet} type="video/mp4" />
                  </video>
                )}
            </div>
          </div>
          
          {currentPosY > 2000 ? (
            <div id="roadmap-section" className="content-section future" style={{ minHeight: browserHeight }}>
            <h2 className={`header-title ${mainStep === 9 ? 'show-title' : 'show-title'}`}>
              Driven for
              <br />
              the Future
              <div className="map-details">
                MDC Season 1: A collective journey towards endless possibilities.
                <br />
                <br />
                Each season will bring new and exciting partnerships, benefits and opportunities to MDC members.
              </div>
            </h2>
            <div className="map-item">
              <MapItemPopup
                toggleCopy="3"
                title="5000 Club Members"
                copy="Once all MDC Helmets have been minted, it's time to hit the road."
                posX={isMobile ? 290 : 905}
                posY={isMobile ? 95 : 80}
                videoContent={newHelmet}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(3)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={3}
                bgColor="#F7B525"
              />
              <MapItemPopup
                toggleCopy="2"
                title="Member Art Giveaways"
                copy="Automobilist posters, Automobilist fine arts, memorabilia — signed posters motorsport merchandise."
                posX={isMobile ? 190 : 680}
                posY={isMobile ? 170 : 230}
                videoContent={newPoster}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(2)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={2}
                bgColor="#43802E"
                isMobileAlignLeft={-80}
                isMobileAlignLeftArrow={88}
              />
              <MapItemPopup
                toggleCopy="1"
                title="Full Metaverse Access"
                copy="Exclusive access to Automobilist's home in the Metaverse."
                posX={isMobile ? 290 : 420}
                posY={isMobile ? 290 : 305}
                videoContent={newFlag}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(1)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={1}
                bgColor="#DF3523"
                isMobileAlignLeft={-210}
                isMobileAlignLeftArrow={218}
              />
              <MapItemPopup
                toggleCopy="4"
                title="Members Exclusive Race Tickets"
                copy="Formula E, WRC, 24h Le Mans, DTM and many more..."
                posX={isMobile ? 390 : 1090}
                posY={isMobile ? 85 : 150}
                videoContent={newTickets}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(4)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={4}
                bgColor="#0A559A"
                isMobileAlignLeft={-200}
                isMobileAlignLeftArrow={208}
              />
              <MapItemPopup
                toggleCopy="5"
                title="Members Only E-store, Auctions & Raffles"
                copy="Exclusive products and early access, exclusive poster series with official serials, exclusive auctions, MDC members only raffles and MDC merchandise."
                posX={isMobile ? 20 : 1285}
                posY={isMobile ? 255 : 245}
                videoContent={newKey}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(5)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={5}
                bgColor="#43802E"
                isMobileAlignLeft={0}
                isMobileAlignLeftArrow={9}
              />
              <MapItemPopup
                toggleCopy="6"
                title="Ambassador Experiences"
                copy="Access to interviews and unique content from official MDC ambassadors."
                posX={isMobile ? 380 : 1060}
                posY={isMobile ? 190 : 325}
                videoContent={newBadge}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(6)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={6}
                bgColor="#F7B525"
                isMobileAlignLeft={-241}
                isMobileAlignLeftArrow={250}
              />
              <MapItemPopup
                toggleCopy="7"
                title="Driver Briefing & Town Halls"
                copy="Discussions on other utilities drivers would like to see in Season 2 and beyond."
                posX={isMobile ? 130 : 610}
                posY={isMobile ? 275 : 480}
                videoContent={newSteering}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(7)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={7}
                bgColor="#DF3523"
                isMobileAlignLeft={-80}
                isMobileAlignLeftArrow={88}
              />
              <MapItemPopup
                toggleCopy="8"
                title="Members Only Physical Meetups"
                copy="Meet and greet the other Metaverse Drivers Club members in person."
                posX={isMobile ? 300 : 305}
                posY={isMobile ? 10 : 550}
                videoContent={newCoffee}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(8)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={8}
                bgColor="#0A559A"
                isMobileAlignLeft={-110}
                isMobileAlignLeftArrow={118}
              />
              <MapItemPopup
                toggleCopy="9"
                title="F1 Fantasy League"
                copy="Create your dream F1 Fantasy League team and race to the top of the leaderboard to win prizes."
                posX={isMobile ? 160 : 150}
                posY={isMobile ? 405 : 385}
                videoContent={newController}
                isMobile={isMobile}
                closeActivePopups={() => closeActivePopups(9)}
                activeRoadmapPopup={activeRoadmapPopup}
                popupId={9}
                bgColor="#DF3523"
                isMobileAlignLeft={-160}
                isMobileAlignLeftArrow={168}
              />
            </div>
          </div>
          ) : <></>}

          <div id="team-section" className="content-section team" style={{ minHeight: browserHeight }}>
            <h2 className={`header-title ${mainStep === 10 ? 'show-title' : 'show-title'}`}>
              TEAM
            </h2>

            <div className="details-container">
              <div className="detail-item">
                <div className="title-text">
                  AUTOMOBILIST
                </div>
                <div className="copy-details">
                  A team of artists, creative thinkers, solution finders,
                  automotive and motorsport aficionados, and perfectionists.
                </div>
              </div>
              <div className="detail-item">
                <div className="title-text">
                  PELLAR
                </div>
                <div className="copy-details">
                  A multidisciplinary Web3, blockchain, metaverse and NFT
                  creative studio specialising in art, collectibles and gaming.
                </div>
              </div>
              <div className="detail-item">
                <div className="title-text">
                  RUN IT WILD
                </div>
                <div className="copy-details">
                  A multidisciplinary Web3, blockchain, metaverse and NFT creative
                  studio specialising in art, collectibles and gaming.
                </div>
              </div>
            </div>

            <div className="team-image-container">
              <div className="cta cta-discord" onClick={() => navDiscord()}>
                <div className="discord-icon"/>
                <span>JOIN DISCORD</span>
              </div>
              <div className="cta cta-litepaper" onClick={() => litepaperClick()}>VIEW LITEPAPER</div>
              <div className="cta-return-top" onClick={() => onClickHome()}>Return to top</div>

            </div>
          </div>
          <div className="footer">
            <div className="footer-logo" onClick={() => onClickHome()}/>
          </div>
        </div>
      </div>
    )
  );
};

MainSite.defaultProps = defaultProps;

export default MainSite;
