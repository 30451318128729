/* eslint-disable react/react-in-jsx-scope */
const Model = () => (
  <model-viewer
    src="https://automobilist-v2-web-app.s3.ap-southeast-2.amazonaws.com/ATM_Asset_WebGLB_v002_Blue_A02_2k_0000.glb"
    environment-image="https://automobilist-v2-web-app.s3.ap-southeast-2.amazonaws.com/versveldpas_4k.jpg"
    ios-src=""
    poster="https://automobilist-v2-web-app.s3.ap-southeast-2.amazonaws.com/loading-glb.png"
    alt=""
    auto-rotate
    // exposure="1"
    // shadow-intensity="0.5"
    enable-pan
    camera-controls
    // disable-zoom
    ar
    ar-modes="scene-viewer webxr"
  />
);

export default Model;
